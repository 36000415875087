import React from "react";
import Typography from "@mui/joy/Typography";
import Pagination from "@mui/material/Pagination";
import {Stack, Table} from "@mui/joy";
import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Link from "@mui/joy/Link";
import Chip from "@mui/material/Chip";

// components
import withNavigate from "../../utils/withNavigate";
import {getNestedField} from "./utils";
import CommonFilterModal from "./CommonFilterModal";
import {generatePath} from "react-router-dom";
import Box from "@mui/joy/Box";


class CommonPaginatedTable extends React.Component{
    render() {
        return (
            <>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {
                        this.props.title?
                            <Typography level="h1" fontSize="xl4">
                                {this.props.title}
                            </Typography>:
                            null
                    }

                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        {
                            this.props.createComponent?
                                <this.props.createComponent
                                    {...this.props.createComponentProps}
                                    callBack={this.props.callBack}
                                />:
                                null
                        }
                        {
                            this.props.filterFields?
                                <CommonFilterModal
                                    callBack={this.props.filterCallBack}
                                    filterFields={this.props.filterFields}
                                />:
                                null
                        }
                    </Stack>
                </Stack>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    size={'sm'}
                >
                    <TableHead>
                        <TableRow>
                            {this.props.fields.map((field) => {
                                if (!field.visible){
                                            return null
                                        }
                                return (
                                    <TableCell key={'header-'+field.name}>
                                        {field.title}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.data.map((each) => {
                            return (
                                <TableRow key={this.props.url+'-'+each.id}>
                                    {this.props.fields.map((field) => {
                                        if (!field.visible){
                                            return null
                                        }
                                        return (
                                            <TableCell key={this.props.url+'-'+each.id+'-'+field.name}>
                                                {
                                                    field.linkPattern?
                                                        <Box
                                                            sx={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Link
                                                                href={
                                                                    generatePath(
                                                                        field.linkPattern,
                                                                        {
                                                                            linkParam: getNestedField(each, field.linkParam)
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                {getNestedField(each, field.name, null, field.preProcess)}
                                                            </Link>
                                                        </Box>
                                                    : field.use_component ?
                                                        <field.component
                                                            componentId={each.id}
                                                            componentObj={each}
                                                            callBack={field.callBack}
                                                        />
                                                    :
                                                        <>
                                                            {
                                                                field.chip?
                                                                    <Chip
                                                                        color={
                                                                            field.color_rule(
                                                                                field.color_value?getNestedField(each, field.color_value):null,
                                                                                field.color_comparison?getNestedField(each, field.color_comparison):null,
                                                                            )
                                                                        }
                                                                        label={getNestedField(each, field.name, null, field.preProcess)}
                                                                        size={'small'}
                                                                    />:
                                                                    <>{getNestedField(each, field.name, field.boolean_negative, field.preProcess)}</>
                                                            }
                                                        </>
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {
                    this.props.pages?
                       <Pagination
                            count={this.props.pages}
                            page={this.props.pageNumber}
                            onChange={
                                (event, value) => {
                                    if (typeof this.props.pagesCallback == 'function') {
                                        this.props.pagesCallback(value);
                                    }
                                    if (this.props.url) {
                                        this.props.navigate(`/${this.props.url}/page/`+value);
                                    }
                                }
                            }
                        />:
                        null
                }
            </>
        )
    }
}

export default withNavigate(CommonPaginatedTable)
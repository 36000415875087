import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from "../Shared/Header";
import Sidebar from '../Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../../context/AuthContext';
import {unstable_createCssVarsTheme as createCssVarsTheme} from "@mui/system";
import mergedTheme from '../../theme.ts';
import ScrollToTop from "./ScrollToTop";
import {UserSubscriberProvider} from "../../context/UserSubscriberContext";


export default function Root(){
    return (
        <AuthProvider>
            <UserSubscriberProvider>
                <ScrollToTop />
                <CssVarsProvider
                    theme={createCssVarsTheme(mergedTheme)}
                >
                    <CssBaseline />
                    <Box
                        id={'main'}
                        sx={
                        {
                            display: 'flex',
                            minHeight: '100dvh',
                            maxHeight: '100dvh',
                            overflow: 'auto'
                        }
                    }>
                        <Header />
                        <Sidebar />
                        <Box
                            component="main"
                            className="MainContent"
                            sx={(theme) => ({
                                px: {
                                    xs: 2,
                                    md: 3,
                                },
                                pt: {
                                    xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                    sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                    md: 3,
                                },
                                pb: {
                                    xs: 2,
                                    sm: 2,
                                    md: 3,
                                },
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                            })}
                        >
                            <Outlet />
                        </Box>
                    </Box>
                </CssVarsProvider>
            </UserSubscriberProvider>
        </AuthProvider>
    )
}

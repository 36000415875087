import React from 'react';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import List from '@mui/joy/List';
import {Stack} from "@mui/joy";
import Link from "@mui/joy/Link";
import Box from "@mui/joy/Box";

// components
import withNavigate from '../../utils/withNavigate';


class CommonSidebarLink extends React.Component {
    render() {
        if (!this.props.item.type) {
            return(
                <ListItem>
                    <ListItemButton onClick={() => this.props.navigate(this.props.item.link)}>
                        {this.props.item.icon}
                        <ListItemContent
                            sx={{
                                'pl': 1,
                            }}
                        >
                            {this.props.item.title}
                        </ListItemContent>
                    </ListItemButton>
                </ListItem>
            )
        }
        switch (this.props.item.type){
            case 'link':
                return(
                    <ListItem>
                        <ListItemButton >
                            <Link
                                href={this.props.item.link}
                                target={'_blank'}
                                color="inherit"
                                underline="none"
                            >
                                {this.props.item.icon}
                                <ListItemContent
                                    sx={{
                                        'pl': 1,
                                    }}
                                >
                                    {this.props.item.title}
                                </ListItemContent>
                            </Link>
                        </ListItemButton>
                    </ListItem>
                )
            case 'component':
                return (
                    <ListItem>
                        <ListItemButton>
                            {this.props.item.icon}
                            <ListItemContent
                                sx={{
                                    'pl': 1,
                                }}
                            >
                                <this.props.item.component />
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                )
            default:
                return (<></>)
        }
    }
}


class CommonSidebar extends React.Component {
    render() {
        return (
            <Stack>
                {
                    this.props.groups.map((group) => (
                        <List
                            key={group.title}
                        >
                            <ListSubheader
                                role='presentation'
                                sx={{color: 'text.primary'}}
                            >
                                {group.title}
                            </ListSubheader>
                            {
                                group.items.map(
                                    (item, index) => {
                                        return (
                                            <Box
                                                key={index}
                                            >
                                                {
                                                    <CommonSidebarLink
                                                        item={item}
                                                        navigate={this.props.navigate}
                                                    />
                                                }
                                            </Box>
                                        )
                                    }
                                )
                            }
                        </List>
                    ))
                }
            </Stack>
        )
    }
}

export default withNavigate(CommonSidebar);

import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import KeyIcon from '@mui/icons-material/Key';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CastIcon from '@mui/icons-material/Cast';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GroupIcon from '@mui/icons-material/Group';


class AdminSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Администрирование',
                        items: [
                            {
                                title: 'Бизнес-процессы',
                                link: '/admin/delivery/',
                                icon: <AccountTreeIcon />,
                            },
                            {
                                title: 'Статистика БП',
                                link: '/admin/delivery_stats/',
                                icon: <QueryStatsIcon />,
                            },
                            {
                                title: 'Маршруты',
                                link: '/admin/route/',
                                icon: <ForkLeftIcon />,
                            },
                            {
                                title: 'Права',
                                link: '/admin/permission/',
                                icon: <KeyIcon />,
                            },
                            {
                                title: 'MindMap',
                                link: '/admin/ideas/',
                                icon: <LightbulbIcon />,
                            },
                        ]
                    },
                    {
                        title: 'HUB',
                        items: [
                            {
                                title: 'Dashboard',
                                link: '/admin/hub_dashboard/',
                                icon: <QueryStatsIcon />,
                            },
                            {
                                title: 'Пользователи',
                                link: '/admin/marketing_user/',
                                icon: <GroupIcon />,
                            },
                            {
                                title: 'Вебинары',
                                link: '/admin/webinar/',
                                icon: <CastIcon />,
                            },
                        ],
                    },
                ]}
            />
        )
    }
}

export default AdminSidebar;
import React from "react";
import RenderIfVisible from 'react-render-if-visible'

import BaseApi from "../../utils/BaseApi";
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import CommentItem from "./CommentItem";
import Box from "@mui/joy/Box";
import { CircularProgress } from "@mui/joy";
import CommentForm from "./CommentForm";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";

// Icons
import TelegramIcon from '@mui/icons-material/Telegram';

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";



class CommentSidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.state = {
            favComments: null,
            comments: null,
            comment_form: <CommentForm ref={this.editor}/>,
        };
    }
    async componentDidMount() {
        await this.retrieveComments();
        await this.retrieveFavComments();
        this.comments_timer = setInterval(this.retrieveComments.bind(this), 30000);
        this.fav_timer = setInterval(this.retrieveFavComments.bind(this), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.comments_timer);
        clearInterval(this.fav_timer);
    }

    async retrieveLists() {
        await this.retrieveComments();
        await this.retrieveFavComments();
    }

    async retrieveFavComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
            favorite: 1,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                favComments: response.data.results
            }
        );
    }

    async retrieveComments(){
        const url = 'comment/';
        const params = {
            related_app_id: this.props.appId,
            related_app: this.props.appName,
            deleted_bool: 0,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                comments: response.data.results,
            }
        );
    }

    async postComment(){
        const url = 'comment/';
        const api = new BaseApi();
        const data = {
                'content': this.editor.current.state.value,
                'related_app_id': this.props.appId,
                'related_app': this.props.appName,
                'deleted_bool': false,
            };
        await api.post(
            url,
            data
        );
        let r = (Math.random() + 1).toString(36).substring(7);
        this.setState(
            {comment_form: <CommentForm ref={this.editor} initialValue={''} key={r}/>}
        );
        await this.retrieveComments();
        await this.retrieveFavComments();
    }

    render() {
        if (!this.state.comments) {
            return (<CircularProgress />)
        }
        if (!this.state.favComments) {
            return (<CircularProgress />)
        }
        if (!this.state.comment_form) {
            return (<CircularProgress />)
        }
        return(
            <Sheet
                variant="plain"
                color="neutral"
                sx={
                    {
                        p: 2,
                        alignContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 'md',
                        width: '100%',
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: 'stretch',
                    }
                }
            >
                <Box sx={
                    {
                        display: 'flex',
                        width: '100%',
                        overflowY: 'scroll',
                    }
                }>
                    <List>
                        {
                            this.state.favComments.map(each=>{
                                return (
                                    <RenderIfVisible
                                        defaultHeight={200}
                                        stayRendered={true}
                                        key={each.id}
                                    >
                                        <CommentItem
                                            comment={each}
                                            favorite={true}
                                            user={this.context.userObject}
                                            callBack={this.retrieveLists.bind(this)}
                                        />
                                    </RenderIfVisible>
                                )
                            })
                        }
                        {
                            this.state.comments.map(each=>{
                                return (
                                    <RenderIfVisible
                                        defaultHeight={200}
                                        stayRendered={true}
                                        key={each.id}
                                    >
                                        <CommentItem
                                            comment={each}
                                            callBack={this.retrieveLists.bind(this)}
                                        />
                                    </RenderIfVisible>
                                )
                            })
                        }
                    </List>
                </Box>
                <Box sx={
                    {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }>
                    <Divider
                        sx={{
                            mt: 2,
                            mb: 2
                        }}
                    />
                    {this.state.comment_form}
                    <Button
                        aria-label="commentSubmit"
                        size={'sm'}
                        color="primary"
                        onClick={this.postComment.bind(this)}
                        sx={{mt: 1}}
                    >
                        <TelegramIcon sx={{mr: 2}}/> Отправить
                    </Button>
                </Box>
            </Sheet>
        )
    }
}

export default CommentSidebar;
